<template>
  <v-form ref="form" v-model="pValid" lazy-validation>
    <v-row>
      <v-col cols="12" lg="6" md="6" sm="6" class="new-service-col">
        <HelpFormInputVue
          dark
          v-model="form.name"
          :rules="nameRules"
          label="Analyze Category Name"
          :helpText="'Provide a name'"
          required
        ></HelpFormInputVue>

        <HelpFormInputVue
          dark
          v-model="form.execution_schedule"
          label=" Execution Schedule"
          :helpText="'Please provide an execution schedule in cron format'"
          required
        ></HelpFormInputVue>

        <HelpFormInputVue
          dark
          v-model="form.link"
          :rules="linkRules"
          label="Link to use"
          :helpText="'This is a basic link with search parameters that will be used as a basis for search engine'"
          required
        ></HelpFormInputVue>

        <!-- CONFIG -->
        <FormSection
          underline
          right
          class="mt-0"
          :label="'Config'"
          :icon="'mdi-cog'"
          :editable="false"
        ></FormSection>
        <NumberInput
          dark
          v-model="form.badThreshold"
          :min="0"
          :step="0.1"
          :max="1"
          :helpText="'The minimum threshold that will be used to filter BAD words similarity'"
        ></NumberInput>

        <NumberInput
          dark
          v-model="form.goodThreshold"
          :min="0"
          :step="0.1"
          :max="1"
          :helpText="'The minimum threshold that will be used to filter GOOD words similarity'"
        ></NumberInput>

        <NumberInput
          dark
          v-model="form.pagesLimit"
          :min="0"
          :step="1"
          :max="20"
          :helpText="'The number of pages to verify on execution'"
        ></NumberInput>

        <HelpFormInputVue
          dark
          v-model="form.stopSelector"
          label="Stop Selector"
          :helpText="'Selector of the element on the page that will stop execution'"
          required
        ></HelpFormInputVue>

        <v-alert prominent type="info" color="blue-grey" class="mt-4">
          <v-row align="center">
            <v-col class="grow">
              Without bad and good words connected all leads found will be
              classified as valid. You'll be able to configure connected words
              and their weights after Analyze Category creation.
            </v-col>
          </v-row>
        </v-alert>
      </v-col>
    </v-row>
  </v-form>
</template>
      
      
  <script>
import HelpFormInputVue from "../../../../atoms/common/inputs/HelpFormInput.vue";
import NumberInput from "../../../../atoms/common/NumberInput.vue";
import FormSection from "../../../../atoms/common/FormSection.vue";

export default {
  props: {
    value: {},
    valid: {
      type: Boolean,
      default: false,
    },
    permissions: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      // Form
      pValid: this.valid,
      form: this.value,

      // ======rules
      nameRules: [(v) => !!v || "Name is required"],
      descriptionRules: [(v) => !!v || "Description is required"],
      linkRules: [
        (v) => !!v || "Link is required",
        (v) => /(https?:\/\/[^\s]+)/.test(v) || "Link should be a proper link",
      ],
    };
  },

  components: {
    HelpFormInputVue,
    NumberInput,
    FormSection,
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
  watch: {
    value(newVal) {
      this.form = newVal;
    },
    form: {
      handler(newVal) {
        this.$emit("input", newVal);
      },
      deep: true,
    },
    pValid(newVal) {
      this.$emit("onValid", newVal);
    },
  },
};
</script>
      
      
  <style lang="scss" scoped>
.new-service-col {
  display: flex;
  flex-direction: column;
  min-width: 200px;
  flex-grow: 1;
  max-width: 100%;
}
</style>