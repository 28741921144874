<template>
  <AView
    :value="value"
    :actions="actions"
    @onActivate="(e) => $emit('onActivate', e)"
    @onMaximize="(e) => $emit('onMaximize', e)"
    @onExpand="(e) => $emit('onExpand', e)"
    @onCollapse="(e) => $emit('onCollapse', e)"
    @onClose="(e) => $emit('onClose', e)"
    @onLeadAnalyzeCategorySave="onCreate"
  >
    <template v-slot:content="{}">
      <NewAnalyzeCategoryForm
        ref="form"
        class="pa-4"
        v-model="form"
        :permissions="value.permissions"
        @action="(action) => action.handler({ from: value })"
      ></NewAnalyzeCategoryForm>
    </template>
  </AView>
</template>
    
    <script>
import { PermissionsHelper } from "../../../../../../components/helpers/permissions.helper";
import NewAnalyzeCategoryForm from "../../../../../../components/wad/organisms/leads/analyzeCategories/forms/NewAnalyzeCategoryForm.vue";
import AView from "../../../../../../components/wad/organisms/layout/A-View.vue";
import { LEAD_ANALYZE_CATEGORIES_BUTTONS } from "../../constants/actions.gh";

export default {
  props: {
    value: {
      default: () => ({}),
    },
  },
  components: {
    NewAnalyzeCategoryForm,
    AView,
  },
  data: () => ({
    form: {
      execution_schedule: "00 00 */1 * * *",
      badThreshold: 0.3,
      goodThreshold: 0.3,
      pagesLimit: 10,
      stopSelector: "div.empty-message-no-match",
    },
  }),
  created() {},
  computed: {
    actions: function () {
      return PermissionsHelper.getActions(
        LEAD_ANALYZE_CATEGORIES_BUTTONS.SAVE,
        this.value.permissions
      );
    },
  },

  methods: {
    onFocus(data) {
      console.log("=============Data: ", data);
    },
    onCreate() {

        console.log('On Create')
      const valid = this.$refs.form.validate();
      if (valid)
        this.$store.dispatch("AnalyzeCategoryStore/CreateNewAnalyzeCategory", {
          from: this.value,
          newAnalyzeCategory: this.form,
        });
    },
  },
};
</script>